<template>
    <div class="modal add_step secondary" v-if="modelValue"  @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_body">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <div class="upload_image image_create m-0">
                    <image-library v-model="form.thumbnail" image-type="playbook-step-thumb" :is-inside="true" />
                </div>
                <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="playbook-add-step" class="playbook-add-step">
                    <div class="step_content">
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Title</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.title }">
                                        <Field autocomplete="off" type="text" name="title" placeholder="ex: Step 1" v-model="form.title" :rules="smartStepRequired ? '' : 'required'" />
                                    </div>
                                    <ErrorMessage name="title" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Description</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.thumb_desc }">
                                        <Field autocomplete="off" type="textarea" name="thumb_desc" v-model="form.thumb_desc" label="description">
                                            <textarea cols="10" rows="10" placeholder="Description Goes Here.." v-model="form.thumb_desc"></textarea>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="thumb_desc" class="text-danger" />
                                </div>
                            </div>
                            <div class="or"><span>Or</span></div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Smart Steps</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.smart_step_id }">
                                        <Field autocomplete="off" name="country" v-model="form.smart_step_id" :rules="smartStepRequired ? 'required' : ''" :class="{ 'has-error': errors.smart_step_id }">
                                            <multiselect
                                                v-model="form.smart_step_id"
                                                :options="smartSteps"
                                                :searchable="true"
                                                valueProp="id"
                                                label="title"
                                                placeholder="Select from saved list..."
                                            ></multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="smart_step_id" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div class="action_wpr">
                            <button :disabled="newStepLoader" class="btn cancel_btn" @click="closeModal()">Close</button>
                            <button :disabled="newStepLoader && form.save_and_edit" class="btn save_btn" @click="form.save_and_edit = 1"><i class="fa fa-spin fa-spinner" v-if="newStepLoader && form.save_and_edit"></i> {{ newStepLoader && form.save_and_edit ? 'Saving' : 'Save And Edit' }}</button>
                            <button :disabled="newStepLoader && !form.save_and_edit" class="btn save_btn" @click="form.save_and_edit = 0"><i class="fa fa-spin fa-spinner" v-if="newStepLoader && !form.save_and_edit"></i> {{ newStepLoader && !form.save_and_edit ? 'Saving' : 'Save' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))

    import Multiselect from '@vueform/multiselect'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Playbook Add Step',

        data () {
            return {
                form: {
                    title: '',
                    thumb_desc: '',
                    thumbnail: '',
                    smart_step_id: 0,
                    save_and_edit: 0,
                    playbook_id: 0,
                },
                newStepLoader: false,
                smartStepRequired: false,
            };
        },

        props: {
            modelValue: Boolean,
            playbook: Object,
            handleStepCreate: Function,
        },

        emits: ['update:modelValue'],

        components: {
            Multiselect,
            Field,
            Form,
            ErrorMessage,
            ImageLibrary,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.getSmartSteps();
                }
            },

            'form.title' (title) {
                const vm = this;

                if (!title) {
                    vm.smartStepRequired = true;
                } else {
                    vm.smartStepRequired = false;
                }
            },

            'form.smart_step_id' (id) {
                const vm = this;

                if (id) {
                    vm.smartStepRequired = true;
                }
            },
        },

        computed: mapState({
            smartSteps: state => state.playbookModule.smartSteps,
        }),

        mounted () {
            const vm = this;

            vm.getSmartSteps();
        },

        methods: {
            ...mapActions({
                getSmartSteps: 'playbookModule/getSmartSteps',
                createNewStep: 'playbookModule/createNewStep',
                getPlaybookStep: 'playbookModule/getPlaybookStep',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                              title: '',
                              thumb_desc: '',
                              thumbnail: '',
                              smart_step_id: 0,
                              save_and_edit: 0,
                              playbook_id: 0,
                          };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError = setFieldError;
                params.playbook_id = vm.playbook.id;

                vm.newStepLoader = true;

                vm.createNewStep(params).then((result) => {
                    const vm = this;

                    if (result) {
                        vm.getPlaybookStep(vm.playbook.id).then(() => {
                            if (params.save_and_edit) {
                                vm.handleStepCreate(result, 1);
                            } else {
                                vm.handleStepCreate(result, 0);
                            }

                            vm.closeModal();
                            vm.newStepLoader = false;
                            vm.resetForm();
                            Toastr.success('Playbook step has been created successfully!');
                        });
                    } else {
                        vm.newStepLoader = false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .modal_body{
        overflow: visible;
    }
    .step_content {
        padding: 20px 30px 10px 30px;
        flex: 1;
    }

    .step_content .action_wpr {
        width: 100%;
        padding-top: 5px;
    }

    .close_btn {
        position: absolute;
        right: 15px;
        left: auto;
        top: 15px;
        border: 0;
        background: rgba(50, 55, 59, .6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width: 20px;
    }

    .or {
        font-size: 14px;
        line-height: 17px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
    }

    .or span {
        padding: 0 10px;
        background: #fff;
    }

    .or:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        border-bottom: 1px solid #e9e9e9;
        z-index: -1;
    }

    form.playbook-add-step {
        width: 100%;
    }

    .upload_image.image_create {
        margin-bottom: 0;
    }

    .upload_image.image_create > .upload_wpr {
        height: 100%;
    }

</style>